<template>
  <div>
    <Row class="mt-10">
      <Col>
        <Button>新增新闻</Button>
      </Col>
    </Row>
    <Table :data="tableData" border style="width: 100%">
      <TableColumn label="日期">
        <template slot-scope="scope">
          <i class="el-icon-time"></i>
          <span style="margin-left: 10px">{{ scope.row.date }}</span>
        </template>
      </TableColumn>
      <TableColumn label="操作" width="180">
        <template slot-scope="scope">
          <Button size="mini" @click="handleEdit(scope.$index, scope.row)"
            >编辑</Button
          >
          <Button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
            >删除</Button
          >
        </template>
      </TableColumn>
    </Table>
  </div>
</template>
  
  <script>
import { Table, TableColumn, Button, Row, Col } from "element-ui";

export default {
  mane: "NewsIndex",
  components: {
    Table,
    TableColumn,
    Button,
    Row,
    Col,
  },
  data() {
    return {
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1517 弄",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1519 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1516 弄",
        },
      ],
    };
  },
  methods: {
    handleEdit(index, row) {
      console.log(index, row);
    },
    handleDelete(index, row) {
      console.log(index, row);
    },
  },
};
</script>
<style scoped>
.mt-10 {
  margin-bottom: 10px;
}
</style>